// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Colors } from "src/assets/colors/colors";
import { material_icons } from "src/assets/google_icons/material_icons";
import englishJson from "src/assets/i18n/en.json";
import { stringFile } from "src/assets/strings/strings";

const ENV = window["env"] || {};

// env variables : check https://pumpingco.de/blog/environment-variables-angular-docker/
const BACKEND_BASE_URL =
  ENV["BACKEND_BASE_URL"] || "https://gnode4.presencesoft.com";

const VERTEX_BE_URL =
  ENV["VERTEX_BE_URL"] || "https://ia-gen-backend-sa6ibfsjvq-od.a.run.app";

const MISTRAL_URL = ENV["MISTRAL_URL"] || "https://pyrone.int.presencedoc.com";

const CLIENT_ID =
  ENV["CLIENT_ID"] ||
  "421035884306-rojbatm7pk40qfmv6p866kj4dc380qo7.apps.googleusercontent.com"; // PSOFT
const SCOPES =
  ENV["SCOPES"] ||
  [
    "profile",
    "email",
    "https://www.googleapis.com/auth/drive"
  ].join(" ");

const AUTO_REFRESH_TOKEN = ENV["AUTO_REFRESH_TOKEN"] || false;

// set LOGS LEVEL, default = *, ex. login,tasks
const LOGS = {};

((ENV["LOGS"] == undefined && "*") || ENV["LOGS"])
  .split(",")
  .map((t) => (LOGS[t] = true));

export const environment = {
  production: false,
  CLIENT_ID: CLIENT_ID,
  AUTO_REFRESH_TOKEN: AUTO_REFRESH_TOKEN,

  BACKEND_URL_Test: BACKEND_BASE_URL,
  BACKEND_URL: BACKEND_BASE_URL,
  GPEOPLE_API: BACKEND_BASE_URL + "/gpeople",
  GCALENDAR_API: BACKEND_BASE_URL + "/gcal",
  SEARCH_API: BACKEND_BASE_URL + "/search",
  VERTEX_BE_URL: VERTEX_BE_URL,
  MISTRAL_URL: MISTRAL_URL,
  HP_CONTACTS: ENV["HP_CONTACTS"],
  HP_EVENTS: ENV["HP_EVENTS"],

  SPACE_ADMIN: !(ENV["SPACE_ADMIN"] == "false"), // default : true
  addDocument: !(ENV["GLOBAL_ADD_DOC"] == "false"),

  SPACE_ADD_DOC: ENV["SPACE_ADD_DOC"],
  SPACE_CREATE_DOC: ENV["SPACE_CREATE_DOC"],

  SUPPORT_WIDGET: ENV["SUPPORT_WIDGET"],

  USER_GUIDE_LINK: ENV["USER_GUIDE_LINK"],
  SUPPORT_LINK: ENV["SUPPORT_LINK"],
  ESPACE_ACTIVATED: ENV["ESPACE_ACTIVATED"],

  appName: "PresenceDoc",

  stringsFile: stringFile.stringsJson,
  defaultStringFile: englishJson,
  scopes: SCOPES && SCOPES.split(" "),

  LOGS,
  searchRoute: ["/search"],

  themeUI: "bootstrap",
  themePath: "pdocs",

  material_icons: material_icons,
  colors_ged: Colors,
  defaultPictureDesign: "/assets/images/icon-word.svg",
  defaultPicture: "https://lh3.googleusercontent.com/a/default-user=s64",
  defaultPictureDoc:
    "https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.document",

  contactLink: "https://contacts.google.com/",
  gmailSearchLink: "https://mail.google.com/mail/u/0/?tab=rm&ogbl#search/",
  calendarLink: "https://calendar.google.com/calendar/u/0/r",
  drivelink: "https://drive.google.com/drive/my-drive",

  accent: "#ff4081",
  primary: "#3f51b5",
  white: "#FFFFFF",
  LOGIN_TEXT: "Login with GOOGLE",

  G_ANALYTICS: ENV["G_ANALYTICS"],

  VERTEX_AI_ENABLED: ENV["VERTEX_AI_ENABLED"],

  MISTRAL_AI_ENABLED: ENV["MISTRAL_AI_ENABLED"],

  URL_FRONT: ENV["URL_FRONT"],

  //oauth server
  BACKEND_OAUTH_URL: ENV["BACKEND_OAUTH_URL"],
  BACKEND_OAUTH_CLIENT_ID: ENV["BACKEND_OAUTH_CLIENT_ID"],
  OAUTH_REDIRECT: ENV["OAUTH_REDIRECT"],
  SCOPES: ENV["SCOPES"],
  BACKEND_OAUTH_PARAMS: ENV["BACKEND_OAUTH_PARAMS"],
  // oauth server end
  DOTS_ENABLED: ENV["DOTS_ENABLED"],


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
